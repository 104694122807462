.application{
    display: flex;
    justify-content: space-around;
    height: 100vh;
  }
  
  .leadForm{
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0px -15px 14px 0px rgb(0 0 0 / 35%);
    flex-direction: column;
    padding-top: 50px;
  }
  
  .contentSpace{
    flex: 2;
      display: flex;
      align-items: center;
      justify-content: center;
      background-image: url(https://img.freepik.com/free-photo/young-manual-worker-unloading-cardboard-boxes-from-delivery-van_637285-2256.jpg?w=2000&t=st=1672313716~exp=1672314316~hmac=3fec30706e74ba43bdcb3dec105430faeb42710817be49587fbb8acb83cabc18);
      background-position: center;
      background-size: cover;
  }
  
  .leadForm .content ul li{
    margin: 10px 0;
  }
  
  .leadForm .content ul li h1{
    margin: 0;
  }
  
  .leadForm .content ul li h3{
    font-weight: normal;
    margin: 10px 0 40px 0;
    color: #6a6a6a;
  }
  
  .content .slides .container{
    background-color: #ffffffbd;
    border-radius: 15px;
    margin: 20px;
  }
  
  .leadForm .content2{
    width: 420px;
    margin-top: 60px;
  }
  
  .slides .container{
    width: 100%;
    padding: 0;
  }
  
  .slides{
    padding: 0 20px;
  }

  .contentSpace .content{
    display: flex;
    flex-direction: column;
    height: 100%;
    min-height: 100%;
    width: 100%;
  }
  
  .content1{
    flex: 4;
  }
  
  .moreServices{
    background: #ffffff;
    padding: 20px;
  }
  
  .moreServices .services{
    display: flex;
  }
  
  .moreServices .services div{
    width: 300px;
  }
  
  .moreServices .services div h4{
    margin-bottom: 10px;
  }

  .centerAlign{
    text-align: center;
  }




  @media (max-width: 768px) {
    .contentSpace {
      display: none;
    }
  }